var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[_c('p',{staticClass:"mt-2"},[_vm._v(" Suivez et gérez ici les disponibilités et rôles des différents intervenants accompagnant vos acquéreurs dans le cadre des rendez-vous de suivi jusqu'à la livraison de leur bien. ")]),_c('v-card',{staticClass:"d-flex align-center pa-2"},[_c('v-text-field',{staticClass:"flex-grow-0 mr-2",attrs:{"label":"Recherche","color":"app-blue","prepend-inner-icon":"fas fa-search","outlined":"","hide-details":"","dense":""},on:{"keyup":function($event){return _vm.doSearch()}},model:{value:(_vm.search.quickSearch),callback:function ($$v) {_vm.$set(_vm.search, "quickSearch", $$v)},expression:"search.quickSearch"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"label":""}},on),[_vm._v(" "+_vm._s(_vm.totalCount)+" ")])]}}])},[_vm._v(" Nombre total des intervenants ")]),_c('ManageStakeholderDialog',{on:{"created":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" Nouvel intervenant ")])]}}],null,true)},[_vm._v(" Ajouter un nouvel intervenant ")])]}}])}),_c('CreateAppointmentDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"app-blue","outlined":"","depressed":""}},on),[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" Rendez-vous ")],1)]}}])}),_c('v-spacer'),_c('v-select',{staticClass:"sort-pagination d-inline-flex flex-grow-0",attrs:{"label":"Trier par","menu-props":{ bottom: true, offsetY: true },"items":_vm.sortList,"background-color":"white","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.fetchAppointments(true)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleOrder()}}},on),[_c('v-icon',{class:{'fa-rotate-180': _vm.by === 'asc'}},[_vm._v(" fas "+_vm._s(_vm.by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down')+" ")])],1)]}}])},[(_vm.by === 'asc')?[_vm._v(" Croissant ")]:_vm._e(),(_vm.by === 'desc')?[_vm._v(" Décroissant ")]:_vm._e()],2)]},proxy:true}]),model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchAppointments(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-simple-table',{staticClass:"rounded elevation-2 my-4"},[[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"0"}}),_c('th',[_vm._v(" Intervenant ")]),_c('th',[_vm._v(" Prochain rendez-vous ")]),_c('th',[_vm._v(" Disponibilités ")]),_c('th',[_vm._v(" Compétences ")]),_c('th',[_vm._v(" Programmes ")]),_c('th',{staticStyle:{"width":"0"}})])]),_c('tbody',_vm._l((_vm.stakeholders),function(stakeholder){return _c('tr',{key:stakeholder.id},[_c('td',[_c('v-avatar',{staticClass:"blue white--text text-uppercase",attrs:{"size":"35"}},[(stakeholder.profilePictureDocumentId)?_c('v-img',{attrs:{"src":_vm.getDocumentUrl(stakeholder.profilePictureDocumentId)}}):_c('span',[_vm._v(" "+_vm._s(stakeholder.fullname ? stakeholder.fullname.slice(0,1) : '')+" ")])],1)],1),_c('td',[_vm._v(" "+_vm._s(stakeholder.fullname)+" ")]),_c('td',[_c('ManageStakeholderPlanningDialog',{attrs:{"initStakeholderIds":[stakeholder.id],"initDisplayDisponibilities":false},on:{"updated":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" far fa-calendar-check")]),_vm._v(" "+_vm._s(stakeholder.nextAppointmentsCount)+" ")],1)]}}],null,true)},[_vm._v(" Cliquer pour visualiser et gérer les rendez-vous et disponibilités ")])]}}],null,true)}),(stakeholder.nextAppointmentDate)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toDatetime")(stakeholder.nextAppointmentDate,false))+" ")])]}}],null,true)},[_vm._v(" Date du prochain rendez-vous ")])]:_vm._e()],2),_c('td',[_c('ManageStakeholderPlanningDialog',{attrs:{"initStakeholderIds":[stakeholder.id],"initDisplayAppointments":false},on:{"updated":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-chip',_vm._g({attrs:{"label":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" far fa-calendar")]),_vm._v(" "+_vm._s(stakeholder.nextDisponibilitiesCount)+" ")],1)]}}],null,true)},[_vm._v(" Cliquer pour visualiser et gérer les rendez-vous et disponibilités ")])]}}],null,true)}),(stakeholder.nextDisponibilitiesDate)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toDatetime")(stakeholder.nextDisponibilitiesDate,false))+" ")])]}}],null,true)},[_vm._v(" Date de la prochaine disponibilité ")])]:_vm._e()],2),_c('td',[(stakeholder.categories.length > 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(stakeholder.categories[0].name)+" ")]):_vm._e(),(stakeholder.categories.length > 1)?_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","small":""}},[_vm._v(" + "+_vm._s(stakeholder.categories.length - 1)+" ")]):_vm._e()],1),_c('td',[(stakeholder.programs.length > 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-city ")]),_vm._v(" "+_vm._s(stakeholder.programs[0].title)+" ")],1):_vm._e(),(stakeholder.programs.length > 1)?_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","small":""}},[_vm._v(" + "+_vm._s(stakeholder.programs.length - 1)+" ")]):_vm._e(),(stakeholder.programs.length === 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","small":"","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-city ")]),_vm._v(" Tous ")],1):_vm._e()],1),_c('td',{staticClass:"white-space-no-wrap"},[(false)?_c('CloneStakeholderDialog',{attrs:{"stakeholderId":stakeholder.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"accent","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-clone ")])],1)]}}],null,true)},[_vm._v(" Dupliquer ")])]}}],null,true)}):_vm._e(),_c('ManageStakeholderDialog',{attrs:{"initStakeholder":stakeholder},on:{"updated":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"app-blue","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier ")])]}}],null,true)}),_c('DeleteStakeholderDialog',{attrs:{"stakeholderId":stakeholder.id},on:{"deleted":function($event){return _vm.fetchAppointments()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"red","small":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,true)})],1)])}),0)]],2),(_vm.totalPages > 1)?_c('v-card',{staticClass:"d-flex align-center pa-2"},[_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"5","color":"app-blue"},on:{"input":function($event){return _vm.fetchAppointments(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }