<template>
    <div class="px-4">
        <p class="mt-2">
            Suivez et gérez ici les disponibilités et rôles des différents intervenants accompagnant vos acquéreurs dans le cadre des rendez-vous de suivi jusqu'à la livraison de leur bien.
        </p>

        <v-card class="d-flex align-center pa-2">
            <v-text-field v-model="search.quickSearch" @keyup="doSearch()" class="flex-grow-0 mr-2" label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip class="mr-2" label v-on="on"> {{ totalCount }} </v-chip>
                </template>
                Nombre total des intervenants
            </v-tooltip>

            <ManageStakeholderDialog @created="fetchAppointments()">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mr-2" small v-on="{ ...dialog, ...tooltip }">
                                Nouvel intervenant
                            </v-btn>
                        </template>
                        Ajouter un nouvel intervenant
                    </v-tooltip>
                </template>
            </ManageStakeholderDialog>

            <CreateAppointmentDialog>
                <template v-slot:activator="{ on }">
                    <v-btn color="app-blue" outlined depressed v-on="on">
                        <v-icon left x-small> fas fa-plus </v-icon>
                        Rendez-vous
                    </v-btn>
                </template>
            </CreateAppointmentDialog>

            <v-spacer />

            <v-select class="sort-pagination d-inline-flex flex-grow-0" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="sortList" background-color="white" @change="fetchAppointments(true)" hide-details outlined dense>
                <template v-slot:append-outer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                    fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-if="by === 'asc'">
                            Croissant
                        </template>
                        <template v-if="by === 'desc'">
                            Décroissant
                        </template>
                    </v-tooltip>
                </template>
            </v-select>

            <v-pagination v-model="page" @input="fetchAppointments(true, false)" :length="totalPages" total-visible="5" color="app-blue" />
        </v-card>

        <v-simple-table class="rounded elevation-2 my-4">
            <template>
                <thead>
                    <tr>
                        <th style="width: 0;">

                        </th>

                        <th>
                            Intervenant
                        </th>

                        <th>
                            Prochain rendez-vous
                        </th>

                        <th>
                            Disponibilités
                        </th>

                        <th>
                            Compétences
                        </th>

                        <th>
                            Programmes
                        </th>

                        <th style="width: 0;">

                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="stakeholder of stakeholders" :key="stakeholder.id">
                        <td>
                            <v-avatar class="blue white--text text-uppercase" size="35">
                                  <v-img v-if="stakeholder.profilePictureDocumentId" :src="getDocumentUrl(stakeholder.profilePictureDocumentId)" />
                                  <span v-else> {{ stakeholder.fullname ? stakeholder.fullname.slice(0,1) : '' }} </span>
                            </v-avatar>
                        </td>

                        <!-- Intervenant  -->
                        <td>
                            {{ stakeholder.fullname }}
                        </td>

                        <!-- Prochain rendez-vous -->
                        <td>
                            <ManageStakeholderPlanningDialog :initStakeholderIds="[stakeholder.id]" :initDisplayDisponibilities="false" @updated="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip label v-on="{ ...dialog, ...tooltip }">
                                                <v-icon left> far fa-calendar-check</v-icon>

                                                {{ stakeholder.nextAppointmentsCount }}
                                            </v-chip>
                                        </template>
                                        Cliquer pour visualiser et gérer les rendez-vous et disponibilités
                                    </v-tooltip>
                                </template>
                            </ManageStakeholderPlanningDialog>

                            <template v-if="stakeholder.nextAppointmentDate">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{ stakeholder.nextAppointmentDate | toDatetime(false) }}
                                        </span>
                                    </template>
                                    Date du prochain rendez-vous
                                </v-tooltip>
                            </template>
                        </td>

                        <!-- Disponibilités -->
                        <td>
                            <ManageStakeholderPlanningDialog :initStakeholderIds="[stakeholder.id]" :initDisplayAppointments="false" @updated="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip label v-on="{ ...dialog, ...tooltip }">
                                                <v-icon left> far fa-calendar</v-icon>
                                                {{ stakeholder.nextDisponibilitiesCount }}
                                            </v-chip>
                                        </template>
                                        Cliquer pour visualiser et gérer les rendez-vous et disponibilités
                                    </v-tooltip>
                                </template>
                            </ManageStakeholderPlanningDialog>

                            <template v-if="stakeholder.nextDisponibilitiesDate">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{ stakeholder.nextDisponibilitiesDate | toDatetime(false) }}
                                        </span>
                                    </template>
                                    Date de la prochaine disponibilité
                                </v-tooltip>
                            </template>
                        </td>

                        <!-- Compétences -->
                        <td>
                            <v-chip v-if="stakeholder.categories.length > 0" class="mr-1" label small>
                                {{ stakeholder.categories[0].name }}
                            </v-chip>

                            <v-chip v-if="stakeholder.categories.length > 1" class="mr-1" label small>
                                + {{ stakeholder.categories.length - 1 }}
                            </v-chip>
                        </td>

                        <!-- Programmes -->
                        <td>
                            <v-chip v-if="stakeholder.programs.length > 0" class="mr-1" label small>
                                <v-icon left> fas fa-city </v-icon>
                                {{ stakeholder.programs[0].title }}
                            </v-chip>

                            <v-chip v-if="stakeholder.programs.length > 1" class="mr-1" label small>
                                + {{ stakeholder.programs.length - 1 }}
                            </v-chip>

                            <v-chip v-if="stakeholder.programs.length === 0" class="mr-1" label small dark>
                                <v-icon left> fas fa-city </v-icon>
                                Tous
                            </v-chip>
                        </td>

                        <td class="white-space-no-wrap">
                            <CloneStakeholderDialog v-if="false" :stakeholderId="stakeholder.id">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="accent" small icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon small> fas fa-clone </v-icon>
                                            </v-btn>
                                        </template>
                                        Dupliquer
                                    </v-tooltip>
                                </template>
                            </CloneStakeholderDialog>

                            <ManageStakeholderDialog :initStakeholder="stakeholder" @updated="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="app-blue" small icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon small> fas fa-edit </v-icon>
                                            </v-btn>
                                        </template>
                                        Modifier
                                    </v-tooltip>
                                </template>
                            </ManageStakeholderDialog>

                            <DeleteStakeholderDialog :stakeholderId="stakeholder.id" @deleted="fetchAppointments()">
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn class="mr-1" color="red" small icon v-on="{ ...dialog, ...tooltip }">
                                                <v-icon small> fas fa-trash-alt </v-icon>
                                            </v-btn>
                                        </template>
                                        Supprimer
                                    </v-tooltip>
                                </template>
                            </DeleteStakeholderDialog>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!-- Pagination -->
        <v-card v-if="totalPages > 1" class="d-flex align-center pa-2">
            <v-spacer />

            <v-pagination v-model="page" @input="fetchAppointments(true, false)" :length="totalPages" total-visible="5" color="app-blue" />
        </v-card>
    </div>
</template>

<script>
import documentsMixin from '../../mixins/documents.js';
import CloneStakeholderDialog from '../../components/appointments/CloneStakeholderDialog.vue';
import CreateAppointmentDialog from '../../components/appointments/CreateAppointmentDialog.vue';
import DeleteStakeholderDialog from '../../components/appointments/DeleteStakeholderDialog.vue';
import ManageStakeholderDialog from '../../components/appointments/ManageStakeholderDialog.vue';
import ManageStakeholderPlanningDialog from '../../components/appointments/ManageStakeholderPlanningDialog.vue';

export default {
    name: 'Appointments',

    mixins: [documentsMixin],

    components: {
        CloneStakeholderDialog,
        CreateAppointmentDialog,
        DeleteStakeholderDialog,
        ManageStakeholderDialog,
        ManageStakeholderPlanningDialog
    },

    data: () => ({
        page: 1,
        limit: 30,
        totalPages: 0,
        totalCount: 0,

        stakeholders: [],

        search: {
            quickSearch: null
        },

        order: 'fullname',
        defaultOrder: 'fullname',
        by: 'asc',
        defaultBy: 'asc',
        sortList: [
            {
                text: 'Intervenant',
                value: 'fullname',
                asc: [{ field: 'fullname', order: 'asc' }],
                desc: [{ field: 'fullname', order: 'desc' }]
            }
        ]
    }),

    methods: {
        doSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchAppointments();
            }, 500);
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchAppointments();
        },

        async fetchAppointments(push = true, resetPage = true) {
            try {
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.displayQuery)) {
                    this.$router.push({ query: this.displayQuery });
                }

                const { stakeholders, count, totalPages, err } = await this.repos.appointments.getStakeholders(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.stakeholders = stakeholders;
                this.totalCount = count;
                this.totalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.order = query.order || this.defaultOrder;
            this.by = query.by || this.defaultBy;
            this.search.quickSearch = query.quickSearch || null;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        }
    },

    computed: {
        query() {
            const order = this.sortList.find((i) => i.value === this.order);
            let items = this.by === 'asc' ? order.asc : order.desc;
            const orderBy = items.map((s) => `${s.field}:${s.order}`).join(',');
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy,
                include: 'disponibilities,programs,categories'
            };

            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }

            return query;
        },

        displayQuery() {
            const query = JSON.parse(JSON.stringify(this.query));
            delete query.limit;
            delete query.include;
            delete query.debug;

            if (query.page === 1) {
                delete query.page;
            }

            delete query.orderBy;
            if (this.order !== this.defaultOrder) {
                query.order = this.order;
            }

            if (this.by !== this.defaultBy) {
                query.by = this.by;
            }

            return query;
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchAppointments(false, false);
    }
};
</script>